module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PWX3PNW8","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr"],"defaultLanguage":"en","siteUrl":"https://iecholden.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/culture-manifesto","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/ethics-compliance","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/private-policy","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/politique-confidentialite","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/ethique-conformite","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]},{"matchPath":"/:lang?/manifeste-culturel","getLanguageFromPath":true,"excludeLanguages":["en","es","fr"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-PWX3PNW8","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
